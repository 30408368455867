<template>
  <b-modal
    id="essilor-pause-modal"
    hide-header
    hide-footer
    no-stacking
    centered
    size="lg"
    @show="onShow"
  >
    <div class="header">
      <div class="header-content">
        <p>Finalizar</p>
        <Close @click="closeModal" />
      </div>
    </div>
    <hr>
    <div class="bottom">
      <div class="body">
        <div class="radio-container">
          <p>Selecione uma situação para essa consulta</p>

          <div class="radio-section">
            <div class="radio">
              <input
                type="radio"
                name="attendance_status"
                id="patient_examination"
                value="pausado"
                @input="getRadioValue"
              >
              <label for="patient_examination">Pausar atendimento para selecionar e continuar em outra sala</label>
            </div>

            <div class="radio">
              <input
                checked
                type="radio"
                name="attendance_status"
                id="attendance_finish"
                value="finalizado"
                @input="getRadioValue"
              >
              <label for="patient_examination">Atendimento finalizado</label>
            </div>

            <div class="radio">
              <input
                type="radio"
                name="attendance_status"
                id="finished_attendance"
                value="finalizado_imprimir"
                @input="getRadioValue"
              >
              <label for="pre-finished_attendance">Finalizar e imprimir</label>
            </div>

          </div>
        </div>
      </div>

      <div class="footer">
        <button class="btn cancel-button" @click="cancelAttendance">
          Cancelar consulta
        </button>
        <div class="btn-group">
          <b-button variant="link" @click="closeModal">
            Voltar
          </b-button>
          <button class="btn confirm-button" @click="pauseAttendance">
            Confirmar
          </button>
        </div>
      </div>
    </div>

  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Close from '@/assets/icons/close.svg'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  components: {
    Close
  },
  computed: {
    ...mapState({
      attendance: state => state.attendance.attendance
    })
  },
  data() {
    return {
      pauseReason: null,
      clinic: getCurrentClinic()
    }
  },
  props: {
    attendanceRedirect: String
  },
  methods: {
    ...mapActions('attendance', ['closeAttendance', 'clearAttendance', 'finishAttendance']),
    onShow() {
      this.pauseReason = 'finalizado'
    },
    closeModal() {
      this.$bvModal.hide('essilor-pause-modal')
    },
    getRadioValue(val) {
      this.pauseReason = val.target.value
    },
    async pauseAttendance() {
      const isLoading = this.$loading.show()
      try {
        await this.essilorPatientOut();

        if(this.pauseReason === "pausado"){
          await this.api.pauseAttendance(this.attendance.clinic.id, this.attendance.id, this.pauseReason)
          this.$toast.success('Consulta pausada com sucesso')
          this.closeAttendanceAndRedirect();
        } else if(this.pauseReason === 'finalizado') {
          await this.onFinishAttendance();
          this.$toast.success('Consulta finalizada com sucesso');
          this.closeAttendanceAndRedirect();
        } else {
          this.onFinishAttendanceAndPrint();          
        }
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    },
    closeAttendanceAndRedirect() {
      this.closeAttendance()
        
      this.attendanceRedirect ?
        this.$router.push(this.attendanceRedirect) :
        this.$router.push('/exames')
      
      this.clearAttendance()
    },
    async essilorPatientOut(){
      await this.api.essilorPatientOut({
        clinic_id: this.clinic.id,
        appointment_item_ids: this.attendance.appointment.appointment_items.filter(item => {
          return item.attendance_id === this.attendance.id
        }).map(item => item.id)
      });
    },
    async onFinishAttendance() {
      this.openFinishOptions = false

      await this.essilorPatientOut(this.attendance, this.clinic.id)

      await this.finishAttendance({})

      this.$emit('onFinishAttendance')
    },
    onFinishAttendanceAndPrint() {
      this.openFinishOptions = false
      this.$bvModal.show('attendance-review-modal')
    },
    async cancelAttendance() {
      this.$swal({
        title: 'Aviso',
        html: `Essa ação é irreversível e resultará na perda das informações.
        </br>
        </br>
        Deseja realmente cancelar essa consulta?`,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
        }).then(async res => {
        if (res.isConfirmed) {
          const isLoading = this.$loading.show()
          try {
            await this.api.essilorPatientOut({
              clinic_id: this.clinic.id,
              appointment_item_ids: this.attendance.appointment.appointment_items.filter(item => {
                return item.attendance_id === this.attendance.id
              }).map(item => item.id)
            })
            await this.api.updateAttendance(this.attendance.clinic.id, this.attendance.patient.id, {
              action: 'end_attendance',
              canceled: true
            })
            this.closeAttendance()
            this.$router.push(`/exames`)
          } catch (error) {
            this.$toast.error(error.message)
          } finally {
            isLoading.hide()
          }
        }
      });
    }
  }
}
</script>
<style lang="scss">
#essilor-pause-modal {
  font-family: 'Nunito Sans';
  font-style: normal;

  input[type="radio"]:checked {
    accent-color: #305bf2 !important;
  }

  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 800px;

    .modal-body {
      padding: 0;

      .header {
        .header-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px;

          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #525C7A;
          }

          svg {
            height: 24px;
            width: 24px;
            fill: #7F91D2;
            cursor: pointer;
          }
        }
      }

      hr {
        margin: 0;
      }

      .bottom {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 24px;

        .body {
          .radio-container {
            display: flex;
            flex-direction: column;
            gap: 24px;

            p {
              font-family: 'Red Hat Display';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 134%;
              letter-spacing: -0.01em;
              color: #0C1D59;
            }

            .radio-section {
              display: flex;
              flex-direction: column;
              gap: 16px;

              .radio {
                display: flex;
                align-items: center;
                gap: 12px;

                label {
                  margin: 0;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 150%;
                  color: #525C7A;
                  cursor: pointer;
                }

                input {
                  height: 24px;
                  width: 24px;
                  cursor: pointer;
                }
              }
            }
          }

          hr {
            margin: 24px 0;
          }
        }

        .footer {
          display: flex;
          justify-content: space-between;

          .cancel-button {
            padding: 14px 0;
            font-weight: 600;
            color: var(--error-main, #E02E1D);
            font-size: 14px;
          }

          .confirm-button {
            padding: 14px 16px;
            background: #305BF2;
            border-radius: 8px;
            font-weight: 600;
            color: #FFFFFF;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.swal2-title {
  font-family: Nunito Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left !important;
  border-bottom: 1px solid #D9DFF2;
  padding: 20px 15px;
}

.swal2-html-container {
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left !important;
}

.swal2-actions {
  justify-content: end !important;
  margin: 10px 10px;  

  &:hover {
      background-image: none !important;
  }
  &:active {
    background-image: none !important;
  }

  .swal2-cancel {
    font-family: Nunito Sans;
    font-size: 14px;
    height: 40px;
    color: var(--primary-main, #305BF2) !important;
    background-color: #FFF;

    &:focus {
      box-shadow: none !important;
    }
  }

  .swal2-confirm {
    font-family: Nunito Sans;
    font-size: 14px;
    height: 40px;
    background-color: var(--primary-main, #305BF2) !important;
  }
  
}
</style>
